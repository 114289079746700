/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        jQuery( '#mainNav a' ).on( 'click', function(e) {
          if ( jQuery(this).parent().hasClass( 'menu-item-has-children' ) ) {
            e.preventDefault();
            if ( jQuery( this ).parent().hasClass( 'in' ) ) {
              jQuery(this).parent().removeClass( 'in' );
            } else {
              jQuery( '.menu-item-has-children.in' ).removeClass( 'in' );
              jQuery(this).parent().addClass( 'in' );
            }
            return false;
          } else {
            jQuery( '.menu-item-has-children.in' ).removeClass( 'in' );
          }
        } );

        $('.frm_checkbox input').after('<span class="checkmark"></span>');
        var menu_items_links = $(".top-menu  li a");
        menu_items_links.each(function () {
            if ($(this).is('[href*="#"]')) {
                $(this).parent().removeClass('current-menu-item current-menu-ancestor');
                $(this).click(function () {
                    var current_index = $(this).parent().index(),
                        parent_element = $(this).closest('ul');
                    parent_element.find('li').not(':eq(' + current_index + ')').removeClass('current-menu-item current-menu-ancestor');
                    $(this).parent().addClass('current-menu-item current-menu-ancestor');
                });
            }
        });

        		// SCROLL TO TOP 
            var h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
           
            $(window).scroll(function () {
              if ($(this).scrollTop() > (50 * h) / 100) {
                  $('.scroll-up').fadeIn();
              } else {
                  $('.scroll-up').fadeOut();
              }
          });
  
          $('.scroll-up').click(function () {
              $("html, body").animate({
                  scrollTop: 0
              }, 600);
              return false;
          });

          jQuery( '.feature' ).on( 'click', function( e ) {
            window.location.href = jQuery( this ).find( 'a' ).attr( 'href' );
          } );
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        if ( jQuery( 'body' ).hasClass( 'page-template-template-product' ) ) {
          jQuery( 'body' ).find( '.frm_forms' ).removeClass( 'frm_forms' );
        }
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
        if ( jQuery(window).width() < 769 && window.location.hash && jQuery( window.location.hash ).length ) {
          jQuery('html, body').animate({
            scrollTop: jQuery( window.location.hash ).offset().top
          }, 1000 );
        }
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
